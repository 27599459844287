.a11y-slider-container {
    position: relative;
    direction: ltr
}

.a11y-slider {
    position: relative;
    overflow: hidden;
    -webkit-scroll-snap-type: x mandatory;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.a11y-slider::-webkit-scrollbar {
    width: 0;
    height: 0
}

.a11y-slider > * {
    scroll-snap-align: start;
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit
}

.a11y-slider-sr-only, .a11y-slider-status {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
    text-align: center;
    font-family: inherit;
    font-size: 1em
}

.a11y-slider-sr-only:focus {
    position: static;
    background: #000;
    color: #fff;
    width: 100%;
    height: auto;
    margin: 0;
    clip: auto;
    padding: 10px
}

.a11y-slider-hide {
    display: none
}

.a11y-slider-scrolling {
    cursor: -webkit-grabbing;
    cursor: grabbing;
    -webkit-scroll-snap-type: none;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    scroll-behavior: auto
}