.one-column-form button {
    background-color: rgb(103, 209, 255);
    appearance: none;
    border: 0px;
    border-radius: 0px;
    box-shadow: rgba(63, 96, 116, 0.2) 0px 9px 11px -5px, rgba(0, 0, 0, 0.15) 0px -3px 0px inset;
    cursor: pointer;
    font-family: mr-eaves-xl-modern-narrow, sans-serif;
    font-size: 1.8rem;
    font-weight: 800;
    letter-spacing: 0.1rem;
    margin-top: 0px;
    padding: 1.2rem 3rem;
    text-transform: uppercase;
    color: rgb(255, 255, 255) !important;

    @media #{$medium-up} {
        font-size: 2rem;
    }
}

.ms-form-eu .one-column-form {
    background: #183446;
    color: #fff;
}

.ms-form-eu .one-column-form p {
    color: #fff;
}

.ms-form-eu .one-column-form button {
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 40px;
    padding: 10px 30px;
    border-radius: 4px;
    border: none;
    background: #ECA426;
    color: #fff;
    font-size: 1.5rem;
}

.ms-form-eu .one-column-form .leftcolumn {
    width: 100%;
}
