.ecom-header-feature {
	background-color: $spray-dk-blue-gray;
	background-image: linear-gradient(90deg, #0065A4 15%, #075671 85%);
	padding: 3.4rem 0 2.6rem;
	position: relative;
	&:before {
		@include position(absolute, null 0 0 0);
		@include size(100%);
		background-image: linear-gradient(0deg, #222 0%, rgba(34,34,34,0) 40%);
		content: '';
		opacity: 0.21;
		z-index: 0;
	}
	@media #{$large-up} { padding: 4.8rem 0 4rem; }
	.content-wrapper { 
		max-width: 100%;
		padding: 0 2rem; 
		position: relative;
		z-index: 1;
		@media #{$large-up} { padding: 0 4rem; }
		@media #{$xlarge-up} { padding: 0 8rem; }
	}
	h1 {
		color: $light;
		font-size: 3.8rem;
		font-weight: 100;
		letter-spacing: -0.7px;
		line-height: 0.85;
		margin-bottom: 0;
		text-transform: uppercase;
		@media #{$small-up} { font-size: 5rem; }
		@media #{$large-up} { font-size: 6.4rem; }
	}
}
.header-crumbs {
	margin-bottom: 0.75rem;
	@media #{$large-up} { margin-bottom: 1.5rem; }
	a, span {
		font-size: 1.4rem;
		@media #{$large-up} { font-size: 1.6rem; }
	}
	a {
		font-weight: 600;
		position: relative;
		color: #5AC1FF;
	}
	i { 
		color: #ffffff;
		opacity: 0.5;
		line-height: 1.1;
        padding-left: 0.25rem;
        @media #{$medium-up} {
			padding-left: 0.5rem; 
			line-height: 1.2;
        } 
    }
	span {
		color: $light;
		font-weight: 500;
		&.desktop {
			display: none;
			@media #{$medium-up} { display: inline-block; }
		}
	}
	.desktop { 
        display: none; 
		@media #{$medium-up} { display: inline-block; }
	}
    .mobile {
        display: inline-block;
		@media #{$medium-up} { display: none; }
		transform: rotate(180deg);
	}
}