.shopping-cart {
	.ecom-aside-item {
		margin-bottom: 1rem;
		&:last-of-type { margin-bottom: 3rem; }
		@media #{$large-up}{
			margin-bottom: 2rem;
		}
		.aside-contact-form {
			box-shadow: 0 10px 20px -5px rgba(63,96,116,0.15), inset 0 0 0 1px rgba(63,96,115,0.25);
			min-height: auto;
			padding: 2rem;
			@media #{$large-up} { padding: 3rem; }
			.js-slide {
				display: none;
			}
			.js-toggle {
				top: 1.5rem;
				right: 2rem;
				@media #{$large-up} { top: 2.5rem; right: 3rem; }
			}
			&.active .js-toggle {
				background: $spray-red;
			}
			&.active .js-toggle .bar {
				border-top: 2px solid $light;
			}
			legend {
				margin-bottom: 0;
				transition: 0.6s all ease;
				@media #{$medium-up} { font-size: 2.4rem; }
				&:after {
					width: 0;
					transition: width 0.3s ease-in;
				}
			}
			&.active legend {
				margin-bottom: 4rem;
			}
			&.active legend:after {
				width: 80px;
			}

			.business-info {
				margin-bottom: 0;
			}
		}
		.js-toggle {
			background: #EEF1F4;
			border-radius: 50%;
			box-shadow: inset 0 -2px 0 rgba(63,96,116,0.3), 0 2px 4px 0 rgba(63,96,116,0.2);
			display: inline-block;
			height: 3rem;
			margin: 0;
			padding: 0;
			right: 2rem;
			top: 1.35rem;
			transition: all 0.35s ease;
			width: 3rem;
			.bar {
				border-top: 2px solid #222;
				position: absolute;
				right: 0.9rem;
				top: 1.4rem;
				transition: all 0.35s ease;
				width: 12px;
			}
		}
	}
	&-checkout-container {
			background-color: #f6f8f9;
			display: flex;
			justify-content: flex-end;
			padding: 2rem;
			border: 1px solid rgba(63, 96, 116, 0.25);
			box-shadow: 0 10px 20px -5px rgba(63,96,116,0.15);
			@media #{$large-up} {
				padding: 3rem;
			}

	}
	&-checkout {
			text-align: right;
			width: 100%;
			@media #{$large-up} { 
				justify-content: center; 
				max-width: 100%;
			}
		&-item {
			margin-bottom: 1.6rem;
			order: 1;
			padding: 0.25rem 0;
			label {
				font-weight: 500;
				margin-right: 1rem;
			}
			label, span {
				color: $dark;
				font-size: 1.6rem;
				@media #{$large-up} { font-size: 1.8rem; }
			}
			span {
				color: $spray-blue;
				font-weight: bold
			}
		}
		&-subtotal {
			text-align: right;
			align-items: center;
			display: flex;
			justify-content: space-between;
		}
		.button-light-blue {
			width: 100%;
			order: 2;
		}
	}
}

