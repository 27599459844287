.site-footer {
	@include clearfix;
	background-color: $footer-color;
	color: $spray-lt-blue-gray;	

	&-container {
		display: flex;
		margin: auto;
		max-width: 1493px;

		@media #{$xlarge-up} { padding: 0 4rem; }
	}

	&-info {
		padding: 3rem 3rem 5rem;
		text-align: center;
		width: 100%;

		@media #{$large-up} {
			text-align: left;
			width: auto;
		}
	}
	&-logo {
		display: none;
		max-height: 100px;

		@media #{$large-up} {
				display: block;
				margin-bottom: 3rem;
		}

		img {
			height: auto;
			max-width: 100%;
			width: 100%;
		}
	}
	.social-icon-list {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		list-style: none;
		margin: auto;
		padding: 0 0 2rem;
		width: 18rem;

		@media #{$large-up} { margin: 0; }

		li { margin-bottom: 0 }
        
		&-item {
			color: $spray-lt-blue-gray;
			font-size: 2.5rem;

            .fa {
                font-family: "FontAwesome" !important;
            }
		}
	}
	.copyright {
		font-size: 1.3rem;
		font-weight: 400;
		margin: 0;
		padding: 0;
		@media #{$xlarge-up} { font-size: 1.4rem; }
	}
	&-links {
		display: none;
		font-weight: 400;
		list-style: none;
		margin: 1rem -0.625rem;

		@media #{$large-up} { display: block; }

		li {
			display: block;
			margin-bottom: 0;
			padding: 0 0.625rem;
			position: relative;

			&:last-of-type:before { display: none; }
			&:first-of-type a { padding-left: 0; }
			a {
				color: $spray-lt-blue-gray;
				display: block;
				font-size: 1.3rem;
				@media #{$xlarge-up} { font-size: 1.4rem; }
				&:hover { text-decoration: underline; }
			}
		}
	}
	&-menu {
		display: none;
	}

	.chat-button {
		display: none;
		@media (min-width: 1201px){
			display: none; // Hide chat button until functional; Set to block to restore
			position: fixed;
			right: 20px;
			bottom: 20px;
			z-index: 11;
			.icon-chat {
				font-size: 5rem;
				color: #ffffff;
				background-color: $spray-ltr-blue;
				padding: 1.5rem;
				text-decoration: none;
				border-radius: 50%;
			}
		}
	}
	
	&.lighter {
		background-color: $lightest-gray;
		color: $darker-gray;
		
		.site-footer-menu ul li a,
		.site-footer-links li a,
		.footer-menu-title {
			color: $darker-gray;
		}

		.social-icon-list-item {
			color: $spray-dk-blue;
		}

		.site-footer {
			.copyright {
				strong {
					font-weight: 500;
				}
			}
		}
	}

	&.darker {
		background-color: $spray-dkr-blue-gray;

		.social-icon-list-item {
			color: $light;
		}

		.site-footer {
			.copyright {
				strong {
					font-weight: 500;
				}
			}
		}
	}
}
@media #{$large-up} {
    .site-footer {
        display: flex;
        padding-top: 3rem;
        padding-bottom: 8rem;
        margin-bottom: 2rem;

        @media #{$xlarge-up} {
            padding-top: 4rem;
        }

        &-info {
            border-right: 1px dashed rbga(255,255,255,0.4);
            padding-top: 0;
            width: 20%;
        }

        &-menu {
            display: flex;
            padding-left: 2rem;
            width: 80%;

            .footer-menu {
                display: flex;
                flex-direction: row;
            }

            ul {
                font-weight: 500;
                line-height: 1;
                list-style: none;
                margin: 0;
                padding: 0 1rem;

                @media #{$xlarge-up} {
                    padding: 0 2rem;
                }

                li {
                    margin-bottom: 1.6rem;

                    &.footer-menu-title {
                        color: $spray-lt-gray;
                        margin-bottom: 2rem;
                        font-weight: 700;
                        text-transform: uppercase;
                        word-break: break-word;

                        @media #{$large-up} {
                            font-size: 1.0rem;
                        }

                        @media #{$xlarge-up} {
                            font-size: 1.0rem;
                            letter-spacing: 0;
                            line-height: 1;
                        }

                        a {
                            pointer-events: none;
                        }
                    }

                    a {
                        color: $spray-lt-blue-gray;
                        font-size: 1.0rem;
                        font-weight: 500;
                        line-height: 1;
                        word-break: break-word;

                        @media #{$xlarge-up} {
                            font-size: 1.0rem;
                        }
                    }
                }
            }
        }
    }
}


