// colors
$spray-lt-gray: #EEF1F4;
$spray-ltr-gray: #E0E0E0;
$spray-gray: #D9DFE3;
$spray-dk-gray: #404040;
$spray-dk-gray-alt: #f6f8f9;
$spray-dk-blue-green: #075671;
$spray-ltst-blue: #2897dc;
$spray-ltr-blue: #67D1FF;
$spray-lt-blue: #24ADFF;
$spray-blue: #0065A4;
$spray-blue-alt: #012b7f;
$spray-blue-alt-1: #004B87;
$spray-blue-alt-2: #0164a7;
$spray-light-blue: #6dcff6;
$spray-dk-blue: #005B94;
$spray-dkr-blue: #284659;
$spray-dkst-blue: #003C50;
$spray-lt-blue-gray: #AEBDC6;
$spray-blue-gray: #5E7C8E;
$spray-dk-blue-gray: #3F6074;
$spray-dkr-blue-gray: #242F37;
$spray-red: #EC3737;
$spray-red-orange: #D47070;
$spray-yellow: #e2d252;
$spray-green: #39B54A;

$light: #FFFFFF;
$lightest-gray: #EAEAEA;
$lighter-gray: #E3E7E9;
$light-gray: #A7A9AC;
$medium-gray: #6A6A6C;
$gray: #808080;
$dark-gray: #363636;
$dark-gray-1: #d2d2d0;
$darker-gray: #3A4B58;
$dark: #222222;
$dark-1: #0f0f04;
$darker: #000000;

$light-blue-hover: #34C2FF;
$dotted-border: 2px dotted rgba(63,96,116,0.2);

$link-color: #006199;
$footer-color: $spray-blue-gray;

// fonts
$site-font: "proxima-nova", sans-serif;
$site-font-secondary: "mr-eaves-xl-modern-narrow",sans-serif;
$site-text-color: $spray-dk-blue-gray;

// order status
$s-processed: #F5A623;
$s-shipped: #7ED321;
$s-delivered: $spray-dk-blue-gray;

// social
$facebook: #3B599B;
$twitter: #55ACEF;
$google-plus: #DC4E41;
$linkedin: #0077B5;
$email: #7D7D7D;
