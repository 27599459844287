.cta-bar-subscribe {
	background-color: $spray-dk-blue-green;
	overflow: hidden;
	padding: 2.8rem 2rem;
	text-align: center;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: baseline;
	margin: 0 0 1rem 0;
	@media #{$medium-up} {
		padding: 2.8rem 4rem;
	}

	.spray-form {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		margin: 1rem;
		min-width: 280px;
		@media #{$medium-up} { flex-flow: row nowrap; }
		@media #{$xlarge-up} { margin-left: 1.25rem; }
		input { 
			border: 0;
			min-width: 280px;
			padding: 1.2rem 0.8rem;
			line-height: 1.3;
			box-shadow: inset 0 3px 0 0 rgba(63,96,116,0.2);
			&:active, &:focus {
				border: 0;
				box-shadow: inset 0 0 0 2.5px #24ADFF;
			}
			@media #{$xlarge-up} {
				padding: 1.4rem 0.8rem;
			}
		}
		button {
			margin: 1rem 0;
			width: 100%;
			@media #{$medium-up} { 
				font-size: 2rem;
				margin: 0 0 0 1rem; 
			}
			@media #{$xlarge-up} {
				line-height: 1.3;
			}
		 }
	}
}