.checkout-menu-wrapper {
    -webkit-overflow-scrolling: touch;
    background-color: $spray-lt-gray;
    height: 100%;
    overflow-y: auto;
    padding: 3.2rem 1rem;
    position: fixed;
    right: -1000px;
    top: 0;
    transition: cubic-bezier(0.79, 0.24, 0.51, 0.95) 0.4s;
    width: 100%;
    z-index: 902;
    @media #{$small-up} { max-width: 400px; }
    @media #{$large-up} { padding: 3.2rem 2rem; }
    &.active { right: 0; }

    .close-search-button {
        opacity: 1;
        .menu-global {  border-color: $dark; }
        @media screen and (min-width: 1201px ) {
            margin-top: 0;
            top: 13px;
        }
    }
    
    .checkout-menu {
        i {
            color: $spray-lt-blue;
            font-size: 2.5rem;
            line-height: 1;
            transition: all 0.2s ease-in;
            cursor: pointer;
            &.active { transform: rotate(45deg); }
            &.icon-arrow {
                cursor: pointer;
                transform: rotate(90deg);
            }
        }

        &-toggle-back { cursor: pointer; }

        &-default-card-wrapper {  
            padding: 0 0.5rem; 
        }

        &-default-card {
            label { font-weight: 500; }
            .checkout-edit { font-weight: 600; }
        }

        &-default-card-outer {
            border-top: 2px dotted rgba(63,96,116,0.2);
            padding: 3rem 1rem 2rem;
            &.selected {
                background-color: #f6f8f9;
            } 
            img {
                max-height: 35px;
                max-width: 50px;
                position: relative;
                top: -10px;
            }
            span, a {
                font-size: 1.6rem;
                margin-left: 0.5rem;
            }

            .checkout-options {
                display: flex;
            }

            .checkout-edit {
                border-right: 2px dotted rgba(63,96,116,0.2);
                font-size: 1.3rem;
                font-weight: 600;
                margin-right: 1rem;
                padding-right: 1.2rem;
                @media screen and (min-width: 375px) { font-size: 1.4rem; }
                @media #{$large-up} { font-size: 1.6rem; }
                &.default {
                    color: $dark;
                }
            }
            @media #{$xlarge-up} {
                margin-right: 4rem;
                &:nth-of-type(2n + 2) {
                    margin-right: 0;
                }
            }
        }

        .checkout-process-title {  
            line-height: 1.3; 
            @media #{$medium-up} { line-height: 1; }
        }

        .checkout-menu-toggle-wrapper {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-top: 2.7rem;
            padding: 0 0 2rem;
        }

        .checkout-menu-toggle {
            align-items: center;  
            display: flex;
        }

        .alpha-sort {
            border-right: 2px dotted rgba(63,96,116,0.2);
            color: $spray-lt-blue;
            cursor: pointer;
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 1.5;
            margin-right: 1rem;
            padding-right: 1rem;
            &:last-of-type {
                border-right: 0;
                margin-right: 0;
                padding-right: 0;
            }
        }

        .filter-text, .checkout-edit-toggle {
            color: $spray-lt-blue;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            font-size: 1.4rem;
            font-weight: 600;
            height: 18px;
            overflow: hidden;
            padding-left: 0.5rem;
            user-select: none;
            @media #{$large-up} { font-size: 1.6rem; }
            .show-filter-text, .hide-filter-text, .show-edit-text, .hide-edit-text {
                position: relative;
                top: 0;
                transition: cubic-bezier(0.79, 0.24, 0.51, 0.95) 0.3s;
                @media #{$large-up} { top: -3px; } 
            }
            &.active .show-filter-text, &.active .hide-filter-text { 
                top: -18px;
                @media #{$large-up} { top: -22px; } 
            }
        }

        .checkout-edit-toggle {
            padding-left: 0;
            .show-edit-text, .hide-edit-text {
                font-size: 1.3rem;
                &:hover { text-decoration: underline; }
                @media #{ $small-up } { font-size: 1.4rem; }
                @media #{$large-up} { 
                    font-size: 1.6rem;
                    top: 0; 
                }
            }
            &.active .show-edit-text, &.active .hide-edit-text {
                top: -16px;
                @media #{$large-up} { top: -20px; }
            }
        }


        &-default-billing {
            padding: 0.5rem 0.5rem;
            img {
                max-height: 35px;
                max-width: 50px;
                position: relative;
                top: -10px;
            }
            span, a {
                font-size: 1.6rem;
                margin-left: 0.5rem;
            }
        } 

        .checkout-select-wrapper {
            @media #{$xlarge-up} {
                margin-right: 4rem;
                &:nth-of-type(2n + 2) {
                    margin-right: 0;
                }
            }
        }

        .checkout-select {
            align-items: flex-start;
            border-top: 2px dotted rgba(63,96,116,0.2);
            justify-content: space-between;
            padding: 2rem;
            &.selected {
                background-color: #f6f8f9;
            }

            label { font-weight: 500; }

            p { 
                margin-left: 3.1rem;
                margin-top: 1rem; 
            }

            p, .checkout-edit {
                font-size: 1.4rem;
                @media #{$large-up} { font-size: 1.6rem; }
            }
            .checkout-edit.default {
                text-decoration: none;
                pointer-events: none;  
                color: $dark;
             }

            .checkout-edit:last-of-type {
                border-left: 2px dotted rgba(63,96,116,0.2);
                margin-left: 1rem;
                padding-left: 1rem;
            }
            &-inner-flex {
                align-items: center;
                display: flex;
                justify-content: space-between;
                .checkout-edit {
                    font-weight: 600;
                    &:first-of-type { margin-left: 3.1rem; }
                    @media screen and (min-width: 375px) { font-size: 1.4rem; }
                    @media #{$large-up} { font-size: 1.6rem; }
                }
            }
            &-button {
                .button-light-blue {
                    padding: 0.8rem 2rem;
                    @media screen and (min-width: 375px) { padding: 0.8rem 3rem; }
                }
            }
        }
    }
}

@media #{$xlarge-up} {
    .checkout-menu-wrapper {  max-width: 800px; }
    .checkout-select-wrapper-outer, .checkout-menu-default-card-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .checkout-select-wrapper, .checkout-menu-default-card-outer {
        width: 50%;
    }
}