.cards-default-container {

    &.cards-default-container-homepage {
        background: $spray-lt-gray;
        margin-top: -1rem;
        padding: 4rem 0 4rem;
    }

    .section-header {
        text-align: center;
        padding: 0 2rem;
        margin-bottom: 2rem;

        @media #{$medium-up} {
            padding: 0 4rem;
        }

        @media #{$large-up} {
            margin-bottom: 4rem;
        }

        p {
            font-size: 1.6rem;

            @media #{$medium-up} {
                font-size: 1.8rem;
            }
        }
    }

    .cards-default-wrapper {
        max-width: 1442px;
        margin: auto;
    }

    .cards-default-grid {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        @media #{$medium} {
            justify-content: center;
            padding: 0;
        }

        &-item {
            margin-bottom: 2rem;
            padding: 0 2rem;
            width: 100%;
            display: flex;
            flex-direction: column;

            > a {
                display: block;
                height: 100%;
                position: relative;
                
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            &.with-product {
                > a {
                    height: 100%;
                }
            }

            @media #{$medium-up} {
                margin-bottom: 0;
                padding: 0 2rem;
                max-width: 454px;
            }

            @media #{$medium} {
                flex: 0 0 44%;
                margin-bottom: 4rem;
            }

            @media (min-width: 1024px) {
                flex: 0 0 31%;
            }
            // IE 11 hack fix
            _:-ms-fullscreen, :root & {
                @media #{$large-up} {
                    flex: 0 0 29%;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &-wrapper {
                background-color: $light;
                border-top: 6px solid $spray-red-orange;
                box-shadow: 0 10px 20px -5px rgba(63,96,116,0.15);
                padding: 3rem 3rem 2rem 3rem;
                text-align: center;
                box-shadow: 0 10px 20px -5px rgba(63,96,116,0.15), inset 0 0 0 1px rgba(63,96,115,0.15);

                &.left {
                    text-align: left;
                }

                @media #{$medium-up} {
                    box-shadow: 0 20px 50px -5px rgba(63,96,116,0.15), inset 0 0 0 1px rgba(63,96,115,0.15);
                }

                @media #{$xlarge-up} {
                    padding: 4rem 4rem 2rem 4rem;
                }

                & > div[sc_item] > i, // icon wrapped in Edit Frame in Experience Editor
                & > i,
                & > a > i {
                    color: $spray-blue;
                    font-size: 6.5rem;
                    margin-bottom: 1rem;

                    @media #{$large-up} {
                        font-size: 7rem;
                        margin-bottom: 2rem;
                    }

                    @media #{$xlarge-up} {
                        font-size: 8.2rem;
                    }
                }
            }

            &-title {
                color: $dark;
                font-size: 2.4rem;
                font-weight: 500;
                line-height: 1.1;
                margin-bottom: 1.4rem;

                @media #{$medium-up} {
                    margin-bottom: 1rem;
                }

                @media #{$large-up} {
                    font-size: 2.4rem;
                    margin-bottom: 1.4rem;
                }

                align-content {
                    color: $dark;
                }
            }

            &-copy p {
                color: $spray-dk-blue-gray;
                font-size: 1.4rem;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.45;
                margin-bottom: 1rem;
                opacity: 0.9;

                @media #{$large-up} {
                    font-size: 1.6rem;
                    line-height: 1.38;
                    margin-bottom: 1.4rem;
                }

                @media #{$xlarge-up} {
                    font-size: 1.6rem;
                    margin-bottom: 2rem;
                }
            }

            &-callout-action {
                align-items: center;
                display: flex;
                justify-content: center;

                a {
                    color: $link-color;
                    font-size: 1.4rem;
                    font-weight: 600;
                    line-height: 1.285;
                    line-height: inherit;
                    position: relative;

                    @media #{$large-up} {
                        font-size: 1.6rem;
                    }

                    @media #{$xlarge-up} {
                        font-size: 1.8rem;
                    }
                }

                i {
                    color: $link-color;
                    font-size: 1.4rem;
                    font-weight: 600;
                    line-height: 1;
                    padding-left: 0.5rem;
                }
            }

            &-wrapper.left .cards-default-grid-item-callout-action {
                justify-content: flex-start;
            }

            &.with-product {
                @media #{$medium-up} {
                    margin-bottom: 4rem;
                }
            }
        }
    }

    &.pad-bottom {
        padding-bottom: 3rem;

        @media #{$large-up} {
            padding-bottom: 4rem;
        }
    }
}

.cards-product-grid {
    .cards-default-grid-item-wrapper {
        height: auto;
    }

    .product-spotlight {
        background-color: $light;
        padding-bottom: 1rem;
        box-shadow: 0 10px 20px -5px rgba(63,96,116,0.15), inset 0 0 0 1px rgba(63,96,115,0.15);

        @media #{$medium-up} {
            box-shadow: 0 20px 50px -5px rgba(63,96,116,0.15), inset 0 0 0 1px rgba(63,96,115,0.15);
        }

        &-title {
            background: $spray-red-orange;
            color: $light;
            font-family: $site-font-secondary;
            font-size: 1.6rem;
            font-weight: 800;
            margin: 0;
            max-width: 100%;
            padding: 0.4rem 2rem;
            width: 100%;

            @media #{$large-up} {
                padding: 0.4rem 4rem;
                font-size: 1.7rem;
            }
        }

        &-grid {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            padding: 2rem 2rem 0;
            width: 100%;
            max-width: 550px;

            @media #{$large-up} {
                padding: 2rem 3rem 0;
            }

            &-image {
                text-align: center;
                padding-right: 1rem;
                width: 30%;
                max-width: 150px;

                @media #{$medium-up} {
                    width: 40%;
                }

                @media #{$large-up} {
                    padding-right: 2rem;
                }
            }

            &-copy {
                width: 70%;

                @media #{$medium-up} {
                    width: 60%;
                }

                &-title {
                    font-size: 1.6rem;
                    font-weight: 500;
                    margin: 0 0 1rem;

                    @media #{$large-up} {
                        font-size: 1.8rem;
                    }
                }

                &-action {
                    a {
                        color: $link-color;
                        font-size: 1.4rem;
                        font-weight: 600;
                        line-height: 1.285;
                        position: relative;

                        @media #{$large-up} {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }
}
