.order-card-header {
	background-color: #f6f8f9;
	display: flex;
	flex-flow: row wrap;
	padding: 1.6rem 2rem 0 2rem;
	&-item {
		margin: 0 0 1.3rem 0;
		padding: 0.25rem 0.25rem 0.25rem 0;
		&:first-of-type { 
			border-right: 0; 
			margin-right: 2rem;
			padding-right: 2rem;
		}
		&.order-hide { display: none; }
		&.order-shipto,
		&.order-price,
		&.order-num-links {
			width: 100%;
		}
		.ship-title { 
			display: block;
			pointer-events: none; 
		}
		.ship-add { 
			display: block;
			font-size: 0.9em; 
			margin-top: 0.6rem;
			i {
				position: relative;
				top: 2px;
			}
		}
		label {
			color: $spray-dk-blue-gray;
			display: block;
			font-size: 1.2rem;
			font-weight: 500;
			letter-spacing: 0.13px;
			margin-bottom: 0.375rem;
			@media screen and (min-width: 1625px) { font-size: 1.6rem; }
		}
		> span {
			color: $dark;
			font-size: 1.6rem;
			font-weight: 500;
			letter-spacing: 0.17px;
			@media screen and (min-width: 1625px) { font-size: 2rem; }
		}	
	}
}
@media screen and (min-width: 768px) and (max-width: 849px), screen and (min-width: 1024px) {
	.order-card-header {
		&-item {
			&:first-of-type { border-right: $dotted-border; }
			&.order-shipto {
				border-right: $dotted-border;
				display: block !important;
				margin-right: 2rem; 
				max-width: 220px;
				padding-right: 1.5rem;
				transition: 0.2s;
				width: auto;
				@media screen and (min-width: 1625px) {
					margin-right: 3rem;
					padding-right: 2.25rem;  
				}
			}
			&.order-price { 
				display: block !important;
				width: auto; 
			}
		}
	}
}
@media #{$medium-up} {
	.order-card-header-item {
		.ship-title {
			cursor: pointer;
			pointer-events: auto;
			position: relative;
			top: -4px;
			user-select: none;
			z-index: 3;
			@media screen and (min-width: 1625px) { top: 0; }
			&:after {
				color: #BFC9D0;
				content: "\e91b";
				font-size: 1.8rem;
				font-family: 'icomoon' !important;
				padding-left: 0.25rem;
				position: relative;
				top: 3px;
			}
		}
		&.ship-to-active {
			.ship-title:after { content: "\e916"; }
		}
		.ship-address { display: none; }
	}
}
@media screen and (min-width: 850px) and (max-width: 1023px), (min-width: 1275px) {
	.order-card-toggle { display: none; }
	.order-card-header {
		padding: 2.4rem 3rem 1.8rem;
		&-item {
			border-right: $dotted-border;
			margin-bottom: 0; 
			margin-right: 2rem;
			padding-right: 2rem;
			transition: 0.2s;
			@media screen and (min-width: 1625px) { 
				margin-right: 3rem; 
				padding-right: 3rem;
				&:first-of-type {
					margin-right: 3rem; 
					padding-right: 3rem;
				}
			}
			&:first-of-type { border-right: $dotted-border; }
			&.order-hide {
				display: block !important;
				width: auto;
			}
			&.order-price { 
				border-right: 0;
				display: block !important; 
			}
			&.order-num-links {
				border-right: 0;
				margin-left: auto;
				margin-right: 0;
				padding-right: 0;
			}
		}
	}
}

.order-card {
	background-color: $light;
	border: 1px solid rgba(63,96,116,0.25);
	box-shadow: 0 20px 50px -5px rgba(63,96,116,0.15);
	margin-bottom: 2rem;
	position: relative;
	@media #{$large-up} { margin-bottom: 4rem; }
	@media #{$xlarge-up} { margin-bottom: 5rem; }
	&-toggle {
		@include position(absolute, -2px 0 null null);
		cursor: pointer;
		height: 72px;
		padding: 8px;
		transition: opacity ease 0.2s;
		width: 100%;
		z-index: 2;
		.bar {
			@include position(absolute, null 1.5rem 3rem null);
			border-top: 3px solid darken($spray-gray, 10%);
			transition: 0.2s;
			width: 30px;
			@media #{$small-up} { right: 2rem; }
			&.lower { transform: rotate(-90deg); }
		}
		.order-card-active & {
			.bar.lower {
				transform: rotate(0deg);
			}
		}
	}
	&-links {
		color: $light-gray;
		display: flex;
		justify-content: flex-start;
		@media screen and (min-width: 374px) { font-size: 1.6rem; }
		@media screen and (min-width: 1625px) { font-size: 2rem; }
		&-item {
			font-size: 1.4rem;
			font-weight: 600;
			padding: 0 0.75rem;
			@media screen and (min-width: 374px) { font-size: 1.6rem; }
			@media screen and (min-width: 1625px) { font-size: 2rem; }
			&:first-of-type { padding-left: 0; }
		}
	}
	&-item {
		border-top: $dotted-border;
		display: flex;
		flex-flow: row wrap;
		padding: 2rem;
		&:first-of-type { border-top: 0; }
	}
	&-info {
		display: flex;
		margin-bottom: 1.5rem;
		width: 100%;
	}
	&-image {
		padding-right: 2rem;
		min-width: 105px;
		width: 105px;
		@media screen and (max-width: 350px) {
			min-width: 85px;
			width: 85px;
		}
		@media #{$large-up} {
			padding-right: 2rem;
			min-width: 130px;
			width: 130px;
		}
		@media screen and (min-width: 1275px) { padding-right: 3rem; }
	}
	&-title {
		color: $dark;
		font-size: 1.8rem;
		font-weight: 500;
		line-height: 1.25;
		margin-bottom: 0.75rem;
		width: 100%;
		@media screen and (min-width: 1275px) {
			font-size: 2.4rem;
		}
	}
	&-meta {
		display: flex;
		flex-flow: row wrap;
		width: 100%;
		&-item {
			align-items: center;
			border-right: $dotted-border;
			display: flex;
			margin: 0.25rem 1rem 0.25rem 0;
			padding-right: 1rem;
			position: relative;
			@media screen and (max-width: 500px) {
				margin-bottom: 0.75rem;
			}
			&:last-of-type { border-right: 0; }
			label, span {
				color: $spray-dk-blue-gray;
				display: inline-block;
				font-size: 1.3rem;
				@media screen and (min-width: 1275px) { font-size: 1.6rem; }
			}
			label { padding-right: 0.5rem; }
			&.qty-meta { border-right: 0; }
		}
	}
	&-actions {
		line-height: 1;
		margin-bottom: 2rem;
		width: 100%;
		@media screen and (max-width: 400px) {
			flex-direction: row;
			.order-card-actions-item {
				width: 49%;
			}
		}
		&-item {
			align-items: center;
			display: flex;
			position: relative;
			i {
				position: absolute;
				left: 0.8rem;
				margin-top: -0.8rem;
				top: 50%;
			}
			span { padding-left: 0.5rem; }
		}
		.cancel-button {
			@extend .button-light-blue;
			align-items: center;
			display: inline-flex;
			position: relative;
			padding: 1.2rem 2rem;
			font-size: 1.6rem;
			@media #{$small-up} {  font-size: 1.8rem; }
			@media #{$medium-up} { font-size: 2rem; }
			@media #{$xlarge-up} { padding: 1.2rem 3rem; }
			i {
				font-size: 2rem;
				position: absolute;
				left: 0.8rem;
				margin-top: -1rem;
				top: 50%;
				@media #{$medium-up} {
					font-size: 2.75rem;
					margin-top: -1.2rem;
				}
				@media #{$large-up} {
					font-size: 3.125rem;
					margin-top: -1.5rem;
				}
			}
			.icon-truck + span {
				@media screen and (max-width: 400px) { 
					padding-left: 1rem;
					letter-spacing: 0.7px;
					margin-right: -1rem;
				}
				@media screen and (min-width: 1275px) { padding-left: 2.25rem; }
				@media #{$xlarge-up} { padding-left: 2.75rem; letter-spacing: 0.5px; }	
			}
			.icon-return + span {
				@media screen and (max-width: 400px) { 
					letter-spacing: 0.8px;
				}
			}
			.icon-cancel + span {
				@media screen and (max-width: 357px) { 
					letter-spacing: 1.1px;
				}
			}
			span { 
				padding-left: 1rem;
				@media #{$small-up} { padding-left: 2.25rem; }
				@media screen and (min-width: 1275px) { padding-left: 3rem; }
			}
		}
		.reorder-button {
			@extend .button-gray;
			align-items: center;
			display: inline-flex;
			position: relative;
			padding: 1.2rem 2rem;
			font-size: 1.6rem;
			@media #{$small-up} {  font-size: 1.8rem; }
			@media #{$medium-up} { font-size: 2rem; }
			@media #{$xlarge-up} { padding: 1.2rem 3rem; }
			i {
				font-size: 2rem;
				position: absolute;
				left: 0.8rem;
				margin-top: -1rem;
				top: 50%;
				@media #{$medium-up} {
					font-size: 2.75rem;
					margin-top: -1.2rem;
				}
				@media #{$large-up} {
					font-size: 3.125rem;
					margin-top: -1.5rem;
				}
			}
			span { 
				padding-left: 1rem;
				@media #{$small-up} { padding-left: 2.25rem; }
				@media screen and (min-width: 1275px) { padding-left: 3rem; }
			}
		}
	}
}
@media screen and (min-width: 768px) and (max-width: 1023px), (min-width: 1275px) {
	.order-card {
		&-item { padding: 3rem; }
		&-info {
			align-self: flex-start;
			padding-right: 1.5rem;
			width: calc(100% - 200px);
		}
		&-actions {
			flex-direction: column;
			width: 200px;
			&-item {
				display: block;
				margin-bottom: 1rem;
				width: 100%;
				span { padding: 0; }
			}
		}
	}
}
@media #{$xlarge-up} {
	.order-card {
		&-info { width: calc(100% - 215px); }
		&-actions { width: 215px; }
	}
}
.order-card-alerts {
	display: flex;
	justify-content: left;
	width: 100%;
	@media screen and (min-width: 1625px) { margin-left: 12rem; }
	&-item {
		color: $darker;
		display: flex;
		flex-direction: column;
		font-size: 1.3rem;
		justify-content: center;
		min-width: 150px;
		padding: 0 0.5rem;
		position: relative;
		text-align: center;
		width: auto;
		@media screen and (max-width: 350px) {
			min-width: 132px;
			&:first-of-type { margin-left: -0.5rem; }
		}
		> i { 
			font-size: 2.5rem; 
			margin-bottom: 0.5rem;
			opacity: 0.3;
			position: relative;
			top: -1px;
			z-index: 2;
		}
		p { 
			font-size: 1.3rem;
			opacity: 0.3;
			margin-bottom: 0.375rem;
			z-index: 2; 
			strong { display: block; }
		}
		.add-reminder, .edit-reminder, .add-notifications, .edit-notifications { 
			cursor: pointer; 
			user-select: none;
			z-index: 2;
			.cancel-text { display: none; }
		}
		.add-reminder, .add-notifications {
			color: $link-color;
			display: block;
			margin: 0 auto;
			text-align: center;
			i { 
				font-size: 2.25rem;
				left: -1.75px;
				position: relative;
				top: 0px;
			}
		}
		.edit-reminder, .edit-notifications { 
			display: none; 
			margin: 0 auto;
			width: 50px;
		}
		&.has-value {
			i, p { opacity: 1; }
			.add-reminder, .add-notifications { display: none; }
			.edit-reminder, .edit-notifications { display: block; }
		}
		&:before {
		    position: absolute;
		    bottom: 0;
		    left: 50%;
		    border-color: #ffffff transparent transparent transparent;
		    border-style: solid;
		    border-width: 20px 15px 0 15px;
		    content: '';
		    height: 0;
		    margin-left: -30px;
		    transition: 0.2s;
		    width: 0;
		    z-index: 1;
		}
		&.reminder-active, &.notifications-active {
			&:before {
			    bottom: -36px;
			    @media screen and (min-width: 768px) and (max-width: 1023px), (min-width: 1275px) {
			    	bottom: -46px;
			    }
			}
			.icon-circle-plus:before { content: "\e90a"; }
			.edit-text { display: none; }
			.cancel-text { display: block; }
		}
	}
}
@media screen and (min-width: 675px) {
	.order-card-alerts {
		justify-content: flex-start;
		&-item {
			align-items: center;
			flex-flow: row wrap;
			justify-content: flex-start;
			margin-right: 2rem;
			padding: 0;
			text-align: left;
			width: auto;
			&.has-value { margin-right: 1rem; }
			&:last-of-type { margin-right: 0; }
			i { 
				margin: 0; 
				padding-right: 0.5rem;
			}
			p {
				margin-bottom: 0;
				padding-right: 0.5rem;
				strong, span { 
					display: inline-block;
					padding-right: 0.5rem; 
				}
			}
		}
	}
}
@media screen and (min-width: 1275px) { 
	.order-card-alerts {
		font-size: 1.6rem;
		&-item {
			font-size: 1.6rem;
			p { font-size: 1.6rem; }
		}
	}
}

.order-card-status {
	background-color: $spray-dk-blue-gray;
	border-radius: 15px;
	color: $light;
	font-size: 1.3rem;
	font-weight: 500;
	padding: 0.5rem 1rem;
	&.s-processed { background-color: $s-processed; }
	&.s-shipped { background-color: $s-shipped; }
	&.s-delivered { background-color: $s-delivered; }
}
@media screen and (min-width: 1275px) {
	.order-card-status {
		font-size: 1.6rem;
		padding: 0.5rem 1.5rem;
	}
}

.order-card-drawer {
	background-color: $spray-dk-blue-green;
	background-image: linear-gradient(90deg, rgba(0,101,164,0.6) 0%, #075671 100%);
	display: none;
	box-shadow: inset 0 30px 100px -20px rgba(34,34,34,0.8);
	padding: 3.8rem 2rem 2rem 2rem;
	position: relative;
	@media screen and (min-width: 768px) and (max-width: 1023px), (min-width: 1275px) {
		padding-left: 3rem;
		padding-right: 3rem;
	}
	.spray-form {
		label {
			color: $spray-lt-gray;
			font-size: 1.6rem;
			font-weight: 600;
			letter-spacing: 0.16px;
			text-transform: none;
		}
		.field-group {
			display: flex;
			justify-content: space-between;
			width: 100%;
			div {
				width: 48%;
			}
		}
		.increment-input {
			align-items: center;
			display: flex;
			> a {
				align-items: center;
				color: $spray-dk-blue-gray;
				cursor: pointer;
				display: flex;
				justify-content: center;
				min-width: 30px;
				padding: 1.45rem 0.5rem;
				@media #{$xlarge-up} { padding: 1.55rem 0.5rem; }
				i { font-size: 2rem; }
			}
			> input { 
				-moz-appearance: textfield;
				-webkit-appearance: none;
				background-color: $light;
				min-width: 60px;
				text-align: center;
				width: 60px; 
				&::-webkit-inner-spin-button,
				&::-webkit-outer-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}
		&-actions {
			a { 
				cursor: pointer;
				margin-bottom: 1rem;
			}
			.cancel-notifications-button, .cancel-reminder-button {
				background-color: transparent;
    			box-shadow: none;
    			padding-left: 2rem;
    			padding-right: 2rem;
			}
		}
	}
}

.set-reminder {
	&-success {
		display: none;
		width: 100%;
		&-wrapper {
			align-items: center;
			display: flex;
			justify-content: center;
			width: 100%;
		}
		i {
			color: $light;
			font-size: 3rem;
			margin-right: 1rem;
			position: relative;
			top: -2px;
		}
		span { 
			color: $spray-lt-gray;
			font-size: 1.6rem;
			font-weight: 600;
			letter-spacing: 0.2px;
			line-height: 1.2;
		}
	}
	.spray-form-actions {
		a:first-of-type { margin-right: 0.625rem; }
	}
	.remind-me-every { display: none; }
	.spray-form-field { max-width: 300px; }
	.reminder-timing-active {
		.remind-me-every { display: block; }
	}
}
@media screen and (min-width: 768px) and (max-width: 1023px), (min-width: 1275px) {
	.set-reminder {
		&-success {
			i { 
				font-size: 4rem;
				top: 0; 
			}
			span { font-size: 2rem; }
		}
		.spray-form {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			&-field {
				margin-right: 2rem;
				max-width: 100%;
				width: 275px;
				&:first-of-type { width: 200px; }
			}
			&-actions {
				margin-top: 2.1rem;
			}
		}
	}
}
@media screen and (min-width: 1800px) {
	.set-reminder {
		.spray-form {
			&-field {
				&:first-of-type { 
					align-items: center;
					display: flex;
					margin-top: 2.1rem;
					width: 360px;
					label { min-width: 160px; }
				}
			}
		}	
	}
}

.set-notifications {
	&-success {
		display: none;
		width: 100%;
		&-wrapper {
			align-items: center;
			display: flex;
			justify-content: center;
			width: 100%;
		}
		i {
			color: $light;
			font-size: 3rem;
			margin-right: 1rem;
			position: relative;
			top: -2px;
		}
		span { 
			color: $spray-lt-gray;
			font-size: 1.6rem;
			font-weight: 600;
			letter-spacing: 0.2px;
			line-height: 1.2;
		}
	}
	.notify-tag {
		&-container {
			align-items: center;
			display: flex;
			flex-flow: row wrap;
		}
		&-item {
			align-items: center;
			display: flex;
			margin: 0 1.25rem 1rem 0;
			> a {
				@include size(20px);
				background-color: #51879A;
				border-radius: 50%;
				color: $light;
				margin-right: 0.75rem;
				i {
					font-size: 2rem;
					&:before { 
						display: block;
						transform: rotate(135deg); 
					}
				}
			}
			span {
				color: $spray-lt-gray;
				font-size: 1.6rem;
				letter-spacing: 0.15px;
			}
		}
	}
}
@media screen and (min-width: 768px) and (max-width: 1023px), (min-width: 1275px) {
	.set-notifications {
		&-success {
			i { 
				font-size: 4rem;
				top: 0; 
			}
			span { font-size: 2rem; }
		}
		.spray-form {
			display: flex;
			justify-content: flex-start;
			flex-flow: row wrap;
			&-field {
				&.select-users {
					margin-right: 2rem;
					width: 275px;
				}
				&.notify-tag {
					width: calc(100% - 295px);
				}
			}
			&-actions { width: 100%; }
		}
		.notify-tag {
			&-container {
				padding-top: 1.25rem;
			}
		}
	}
}
@media screen and (min-width: 1625px) {
	.set-notifications {
		.spray-form {
			.notify-tag { 
				margin-right: auto;
				width: calc(100% - 615px);
			}
			&-actions { 
				margin-top: 2.1rem;
				width: 320px; 
			}
		}
	}
}