div.spray-table {
    margin-bottom: 1rem;
    max-width: 794px;
    
    &-wrapper { 
        margin: 4rem 0;
        max-width: 794px;
        overflow-x: auto;
    }
    &-header, &-row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 794px;
        
    }
    &-header {
        background-color: $dark;
        color: $light;
        border-bottom: 5px solid $spray-dk-blue-gray;
        .spray-table-cell {
            font-size: 1.6rem;
            font-weight: bold;
            text-transform: uppercase;
            border-right: 2px dotted rgba(255, 255, 255, 0.3);
            &:last-of-type { border-right: none; }
        }
    }
    &-cell {
        padding: 1rem;
        width: 100%;
        text-align: left;
        font-size: 1.6rem;
        text-align: left;
        &-title {
            font-weight: bold;
            margin-bottom: 1rem; 
            text-align: center;
        }
        &-image {
            text-align: center;
            img { 
                border-radius: 50%;
                max-width: 100%;
            }
        }
        &.one { text-align: center; }
        &.two { min-width: 185px; width: 100%; } 
        &.four { min-width: 300px; width: 100%; } 
    }
    &-row {
        .spray-table-cell {
            border-right: 2px dotted rgba(63,96,116,0.2);
            color: $spray-dk-blue-gray;
            font-size: 1.6rem;
            font-weight: 500;
            padding: 1.5rem 1rem;
            ul {
                margin-bottom: 0;
                li {
                    text-align: left;
                    font-size: 1.6rem;
                    font-weight: 500;
                    margin: 0 0 0 2rem;
                }  
            }
        }
        &:nth-of-type(even) { background-color: #f6f8f9; }
        &:last-of-type { border-bottom: 2px dotted rgba(63, 96, 116, 0.2); }
    }

    &-link {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        a { font-weight: 600; }
    }

    //eductor sizing table overrides
    &.eductor-sizing {
        max-width: 100%;
        min-width: 950px;
        .spray-table-row { 
            background-color: #ffffff; 
            border-bottom: 2px dotted rgba(63, 96, 116, 0.4);
            max-width: 100%;
            min-width: 950px;
            margin: auto;
         }
        .spray-table-cell { text-align: center; }
        .spray-table-header {  
            max-width: 100%;
            min-width: 950px;
            margin: auto;
        }
        .spray-table-cell.one { 
            min-width: 100px; 
            .pad-top { padding-top: 3rem; }
            .spray-table-cell-title { padding-top: 6rem; }
        }
        .spray-table-cell.two.header {
            padding: 1rem;
        }
        .spray-table-cell.two { 
            min-width: 180px;
            padding: 0;
            .pad-top { padding-top: 3rem; } 
            .spray-table-sub-row { 
                padding: 1rem;
                border-bottom: 2px dotted rgba(63, 96, 116, 0.4); 
                &:last-of-type { border-bottom: 0; }
            }
        }
        .spray-table-cell.three.header {
            .header-span { padding: 0 1rem; margin-bottom: 1rem; } 
            min-width: 600px;
            padding:  1rem 0 0;
            .spray-table-sub-col { 
                border-right: 2px dotted rgba(255, 255, 255, 0.3);
                &:nth-last-oftype { border-right: 0; }
             }
        }
        .spray-table-cell.three {
            min-width: 600px;
            padding: 0;
            border-right: 0;
            .spray-table-sub-row {
                 padding: 0; 
                 border-bottom: 2px dotted rgba(63, 96, 116, 0.4);
                 &:last-of-type { border-bottom: 0; }
                }
            .spray-table-sub-col { 
                padding: 1rem;
                border-right: 2px dotted rgba(63, 96, 116, 0.2);
                &:last-of-type { border-right: 0; }
             }
        }
        .spray-table-sub-row {
            display: flex;
            font-size: 1.4rem;
            font-weight: 500;
            justify-content: center;
            border-top: 2px dotted rgba(255, 255, 255, 0.3);
            &.gray { background-color: #f6f8f6; }
        }
        .spray-table-sub-col {
            min-width: 11.1%;
            padding: 1rem;
            border-right: 2px dotted rgba(255, 255, 255, 0.3);
            &:last-of-type { border-right: 0; }
            font-size: 1.4rem;
            font-weight: 500;
        }
    }
}
.reference-table {
    padding: 0 0 0 2rem;
    display: none;
    @media screen and (min-width: 780px ) { padding: 0 2rem; }
    .scroll-instructions { 
        margin-top: 4rem; 
        border-bottom: 1px dotted gray;
        line-height: 0;
        margin-bottom: 1.5rem;
        margin-left: 0.5rem;
        text-align: right;
        span { 
            background: #eef1f4; 
            font-size: 1.4rem; 
            padding-left: 1rem; 
            line-height: 0; 
        }
        i.icon-arrow { line-height: 0; transform: rotate(-90deg); font-size: 2.4rem; }
    }
    .spray-table-wrapper { margin-top: 0; }
}
table.spray-table {
    table-layout: auto;
    border-collapse: separate;
    margin-bottom: 1rem;
    max-width: 794px;
    width: 100%;
    &.full-width { max-width: none; }
    
    &-wrapper {
        margin: 4rem 0;
        max-width: 794px;
        overflow-x: auto;
    }

    tr:first-of-type {
        background-color: $dark;
        color: $light;
        td, th {
            font-size: 1.6rem;
            font-weight: bold;
            text-transform: uppercase;
            border-right: 2px dotted rgba(255, 255, 255, 0.3);
            border-bottom: 5px solid $spray-dk-blue-gray;
            &:last-of-type { border-right: none; }
        }
    }
    td, th {
        padding: 1rem;
        width: auto;
        text-align: left;
        font-size: 1.6rem;
        text-align: left;
        &-title {
            font-weight: bold;
            margin-bottom: 1rem; 
            text-align: center;
        }
        &-image {
            text-align: center;
            img { 
                border-radius: 50%;
                max-width: 100%;
            }
        }
        &.one { text-align: center; }
        &.two { min-width: 185px; width: 100%; } 
        &.four { min-width: 300px; width: 100%; } 
    }
    tr:not(:first-of-type) {
        background-color: #ffffff;
        td {
            border-right: 2px dotted rgba(63,96,116,0.2);
            color: $spray-dk-blue-gray;
            font-size: 1.6rem;
            font-weight: 500;
            padding: 1.5rem 1rem;
            ul {
                margin-bottom: 0;
                li {
                    text-align: left;
                    font-size: 1.6rem;
                    font-weight: 500;
                    margin: 0 0 0 2rem;
                }  
            }
        }
        &.highlight {
            td:first-of-type {
                border-left: 5px solid;
            }
        }
        &:nth-of-type(even) { background-color: #f6f8f9; }
        &:last-of-type { border-bottom: 2px dotted rgba(63, 96, 116, 0.2); }
    }

    .spray-table-link {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        a { font-weight: 600; }
    }
}

table.simple-list {
    margin: auto;
    td {
        padding: 0.25rem 1rem;
    }
    td:first-of-type {
        font-weight: 700;
    }
}
