.order-filters-header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 1rem 0;
	&-container { width: 100%; }
}
@media screen and (min-width: 1280px) {
	.order-filters-header-container { display: none; }
}

.order-tabs {
	display: none;
}
@media screen and (min-width: 1280px) {
	.order-tabs {
		border-bottom: $dotted-border;
		display: flex;
		width: 100%;
		&-item {
			align-items: center;
			color: $link-color;
			cursor: pointer;
			display: flex;
			font-size: 1.7rem;
			font-weight: 600;
			padding: 1rem 1rem;
			position: relative;
			text-align: center;
			user-select: none;
			&:hover { 
				text-decoration: none; 
				&:after {
					@include position(absolute, null 1rem -0.4rem 1rem);
					border-bottom: 6px solid $spray-red-orange;
					content: "";
					display: block;
					height: 6px;
					width: calc(100% - 2rem);
				}
			}
			&:after {
				@include position(absolute, null 1rem -0.4rem 1rem);
				border-bottom: 6px solid $spray-red-orange;
				content: "";
				display: none;
				height: 6px;
				width: calc(100% - 2rem);
			}
			&.active-tab{
				color: $dark;
				pointer-events: none;
				&:hover { text-decoration: none; }
				&:after { display: block; }
			}
			i {
				color: rgba($spray-dk-blue-green, 0.3);
				font-size: 2rem;
				margin-left: 0.5rem;
				text-align: center;
				&:before {
					position: relative;
					top: 0;
				}
			}
		}
	}
}
@media screen and (min-width: 1625px) {
	.order-tabs {
		&-item {
			font-size: 1.8rem;
			padding: 1rem 2rem;
			&:after {
				left: 2rem;
				right: 2rem;
				width: calc(100% - 4rem);
			}
		}
	}
}
@media screen and (min-width: 1810px) {
	.order-tabs {
		&-item {
			font-size: 2rem;
			padding: 1rem 3rem;
			&:after {
				left: 3rem;
				right: 3rem;
				width: calc(100% - 6rem);
			}
		}
	}
}

.order-search-trigger {
	align-items: center;
	cursor: pointer;
	display: flex;
	user-select: none;
	i {
		align-items: center;
		color: $darker;
		display: flex;
		font-size: 2.5rem;
		height: 30px;
		justify-content: center;
		position: relative;
		transition: cubic-bezier(0.79, 0.24, 0.51, 0.95) 0.3s;
		width: 30px;
	}
	.search-text {
		color: $link-color;
		font-size: 1.6rem;
		letter-spacing: 0.17px;
	}
}



@media screen and (min-width: 1280px) {
	.order-filters {
		display: flex;
		.order-show-field { display: none; }
	}
}
.order-sort {
	display: flex;
	flex-direction: column;
	padding: 2rem 1.5rem;
	width: 100%;
	@media #{$small-up} {
		align-items: center;
		justify-content: center;
		margin: 0 auto;
	}
	&-container { display: none; }
	&-field {
		align-items: center;
		display: flex;
		max-width: 275px;
		label {
			margin-bottom: 0;
			min-width: 110px;
			padding-right: 1rem;
			text-align: right;
			span {
				font-weight: 300;
				text-transform: none;
			}
		}
		select { width: 165px; }
	}
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
	.order-sort {
		display: flex;
		flex-direction: row;
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
		&-field {
			align-items: flex-start;
			flex-direction: column;
			margin-bottom: 0;
			margin-right: 2rem;
			max-width: 100%;
			width: 100%;
			&:last-of-type { margin-right: 0; }
			label { 
				margin-bottom: 0.6rem;
				min-width: 0;
				text-align: left; 
			}
			select { width: 100%; }
		}
	}
}
@media screen and (min-width: 1280px) {
	.order-sort {
		flex-direction: row;
		padding: 2rem 2rem 2rem 0;
		&-container { display: block; }
		&-field { margin-bottom: 0; }
	}
}
@media #{$xlarge-up} {
	.order-sort {
		padding: 4rem 2rem 4rem 0;
	}
}

.order-search {
	display: none;
	&-wrapper {
		padding: 1rem 0 1.5rem;
		label { display: none; }
	}
	&-input { 
		position: relative; 
		width: 100%;
		input[type=text] { 
			background-color: $light; 
			padding-right: 4rem;
			&::-ms-clear { display: none; }
		}
		input[type=submit] {
			@include position(absolute, 1px 0.75rem null null);
	        background-color: transparent;
	        background-image: url(../images/template/magnifying.svg);
	        background-position: center 6px;
	        background-repeat: no-repeat;
	        border: 0;
	        cursor: pointer;
	        font-size: 2rem;
	        height: 49px;
	        outline: 0;
	        text-indent: -9999px;
	        width: 38px;
		}
		&-icon {
			font-size: 3rem;
			position: absolute;
			right: 0.75rem;
			top: 0.75rem;
			&.order-search-active {
				i:before { 
					content: '\e91b'; 
					display: block;
					transform: rotate(135deg);
				}
			}
		}
	}
}
@media screen and (min-width: 1280px) {
	.order-search {
		display: block;
		margin-left: auto;
		&-wrapper {
			align-items: center; 
			display: flex;
			padding: 2rem 0rem 2rem 2rem; 
		}
	}
}
@media #{$xlarge-up} {
	.order-search {
		&-wrapper { padding: 4rem 0 4rem 2rem; }
	}
}
@media screen and (min-width: 1700px) {
	.order-search { 
		width: 420px; 
		label { 
			display: block; 
			margin-bottom: 0;
			padding-right: 1rem;
		}
	}
}