.thumbnail-container {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	width: 25rem;
	padding: 2rem;

	span[data-caption] {
		max-width: 21rem;
		text-align: center;
	}

	img {
		width: 21rem;
		height: 21rem;
		object-fit: contain;
	}
}
img.object-fit-contain-ie {
	display: block;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}
