
.cards-feature-container {
	width: 100%;
	max-width: 1430px;
	padding: 3rem 11px 5.5rem;
	margin: 0 auto;

	@media #{ $medium-up } { padding: 5rem 3rem 5rem; }
	

	&.alt-padding  {
		padding: 2rem 2rem 5.5rem;

			@media #{ $large-up } { padding: 2rem 2rem 8rem; }
	}

	.section-header {
		text-align: center;
		padding-bottom: 2.5rem;
		@media #{ $large-up } { padding-bottom: 4rem; }
	}

	&-action {
		text-align: center;

		.button {
			margin: 3rem auto 0;
		}
	}
}

.cards-feature-grid {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	flex: 0 0 50%;
}

.cards-feature-item {
	display: block;
	margin: 0 0 3rem;
	max-width: 680px;
	width: 100%;

	@media #{ $medium-up } { width: 50%; margin: 0; padding: 0 1rem;}
	@media #{ $large-up } { padding: 0 2rem; }

	&:last-of-type { margin-bottom: 0; }

	&-wrapper {
		@media #{ $xsmall-up } { max-width: 100%; }
		@media #{ $medium-up } { margin:0 auto; max-width: 100%; }
	}

	&-text, &-image {
		border: none;
		width: 100%;

		@media #{$large-up} { text-align: left; }
	}

	&-image {
		background-size: cover;
		padding-bottom: 56%;
		align-self: flex-start;
		min-height: 1px;
		margin-bottom: 2.4rem;
		position: relative;

		@media #{$medium-up} { width: 50%; max-width: 660px; }
		@media #{$large-up} { width: 55%; }
		@media #{$medium-up} { width: 100%; max-width: 100%; }
		@media #{$large-up} { width: 100%; }

		.button-logo { max-width: 50%; }
		.button-play { max-width: 100px; }

		.button-play,
		.button-logo {
			height: auto;
			left: 50%;
			margin: 0;
			padding: 0;
			position: absolute;
			text-align: center;
			top: 40%;
			transform: translate(-50%, -40%);
			width: auto;
			z-index: 10;
			cursor: pointer;

			@media #{$medium-up} {
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
			span {
				border-bottom: 2rem solid transparent;
				border-left: 3.5rem solid white;
				border-top: 2rem solid transparent;
				display: inline-block;
				height: 0;
				left: 55%;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 50%;
				transform: translate(-55%, -50%);
				width: 0;

				@media #{$medium-up} {
					border-bottom: 1rem solid transparent;
					border-left: 2rem solid white;
					border-top: 1rem solid transparent;
				}
				@media #{$large-up} {
					border-bottom: 2rem solid transparent;
					border-left: 3.5rem solid white;
					border-top: 2rem solid transparent;
				}
			}
		}

		a { border: 0; }

		img,
		video,
		iframe { max-width: 100%; height: auto; width: 100%; }

	} // &-image

	&-image.img-overlay:before {
		background: rgba($spray-dk-blue-gray, 0.4);
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}


	&-text {
		margin-top: 1rem;
        margin-bottom: 1rem;
		padding: 0;
		text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

		@media #{$medium-up} { width: 100%; margin-top: 2rem; }
		@media #{$large-up} { margin-top: 3rem; }

		@media #{$medium-up} {
			// padding: 8rem;
			text-align: left;
		}

		h3.eyebrow  {
			border: none;
			color: #222;
			font-size: 2.2rem;
			font-weight: 500;
			letter-spacing: 0.24px;
			line-height: 1.2;
			margin: 0 0 1rem;
			padding: 0;
			text-transform: none;

			@media #{$large-up} { font-size: 2.2rem; }
			@media #{$xlarge-up} { font-size: 2.2rem; }
		}

		&-copy {
			color: $spray-dk-blue-gray;
			font-size: 1.4rem;
			font-weight: 400;
			line-height: 1.466;
			margin-bottom: 0;
			opacity: 0.9;
			padding: 0 0 1rem;

			@media #{$medium-up} { padding: 0; font-size: 1.6rem; margin-bottom:1rem; }
			@media #{$large-up} {
				font-size: 1.6rem;
				line-height: 1.636;
			}
			@media #{$xlarge-up} { font-size: 1.6rem; }
		}

		&-action {
			display: block;
			font-size: 1.6rem;
			font-weight: 600;
			line-height: 1.466;

				@media #{$medium-up} { font-size: 1.8rem; }
				@media #{$large-up} {
					font-size: 1.8rem;
					line-height: 1.636;
					max-width: 500px;
				}
				@media #{$xlarge-up} { font-size: 1.8rem; }

			a {
				color: $spray-lt-blue;
				font-size: inherit;
				font-weight: inherit;
				line-height: inherit;
				position: relative;
				white-space:nowrap;

				@media #{$large-up} { max-width: 500px; }
			}
			i {
        color: #5AC1FF;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }
		}
	}
}

@media #{$medium-up} {
	.cards-feature {
		flex-direction: row;
		&.cardsLeft {
			.cards-feature-image {
				padding-left: 0;
				padding-right: 0;
				order: 1;
			}
			.cards-feature-text {
				padding-right: 0;
				order: 1;
			}
		}
		&.cardsRight {
			.cards-feature-image {
				padding-left: 0;
				padding-right: 0;
				order: 1;
			}
			.cards-feature-text {
				padding-left: 0;
				order: 1;
			}
		}
		&-text { padding: 0; }
	}
}

