.stat-lineup {
    &-container {
        background-color: #fff;
        padding: 3rem 0 0;
        margin: 0 auto;
        @media #{$medium-up} { padding: 6rem 0 0; }
        @media #{$large-up} { padding: 8rem 0 0; }
    }
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    @media screen and (min-width: 600px) { max-width: 900px; }
    @media screen and (min-width: 1500px) { max-width: 100%; }

    &-item {
        padding: 1rem;
        max-width: 200px;
        @media #{$small-up} { padding: 2rem; max-width: 200px; }
        @media #{$medium-up} { padding: 2rem 3rem; max-width: 250px; }
        @media #{$large-up} { padding: 2rem 4.5rem; max-width: 300px; }
        
        &-num {
            font-size: 6rem;
            font-weight: 900;
            line-height: 1;
            color: $spray-blue;
            margin-bottom: 0;
            @media #{$medium-up} { font-size: 7rem; }
            @media #{$large-up} { font-size: 8rem; }
        }
        &-desc {
            font-size: 1.6rem;
            font-weight: 700;
            color: $spray-dk-blue-gray;
            margin-bottom: 0;
            @media #{$medium-up} {  font-size: 1.8rem; }
        }
    }
}