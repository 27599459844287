.video-feature-container {
    background: $light;
    padding: 2rem 0;

    @media (min-width: 768px) {
        padding: 4rem 0;
    }
}

.video-feature {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 2rem 0;

    @media #{$medium-up} {
        padding: 0 4rem 0;
    }

    @media (min-width: 1024px) {
        max-width: 1620px;
    }

    @media screen and ( min-width: 1201px ) {
        padding-left: 10rem;
        padding-right: 10rem;
    }

    @media screen and ( min-width: 1441px ) {
        padding-left: 12rem;
        padding-right: 12rem;
    }

    &-top {
        width: 100%;
        padding-bottom: 1rem;
    }

    &-bottom {
        width: 100%;
        padding-top: 1rem;
    }

    &-link {
        width: 100%;
        padding-top: 0;
    }

    &-image {
        min-height: 1px;
    }

    &-text, &-image {
        border: 0;
        width: 100%;

        @media #{$large-up} {
            text-align: left;
        }

        img {
            max-width: 500px;
            width: 100%;
        }
    }

    &-image {
        align-self: flex-start;
        position: relative;

        @media (max-width: 767px) {
            padding-bottom: 2rem;
            margin-top: 1.5rem;
        }

        @media #{$medium-up} {
            max-width: 660px;
            width: 50%;
        }

        @media #{$large-up} {
            width: 50%;
        }

        .button-logo {
            max-width: 50%;
        }

        .button-play {
            max-width: 15%;
        }

        .button-play,
        .button-logo {
            @include position(absolute, 40% null null 50%);
            cursor: pointer;
            height: auto;
            margin: 0;
            padding: 0;
            text-align: center;
            transform: translate(-50%, -40%);
            width: auto;
            z-index: 10;

            @media #{$medium-up} {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            span {
                @include position(absolute, 50% null null 55%);
                border-bottom: 2rem solid transparent;
                border-left: 3.5rem solid $light;
                border-top: 2rem solid transparent;
                display: inline-block;
                height: 0;
                margin: 0;
                padding: 0;
                transform: translate(-55%, -50%);
                width: 0;

                @media #{$medium-up} {
                    border-bottom: 1rem solid transparent;
                    border-left: 2rem solid $light;
                    border-top: 1rem solid transparent;
                }

                @media #{$large-up} {
                    border-bottom: 2rem solid transparent;
                    border-left: 3.5rem solid $light;
                    border-top: 2rem solid transparent;
                }
            }
        }

        a {
            border: 0;
        }

        img,
        video,
        iframe {
            height: auto;
            max-width: 100%;
            width: 100%;
        }
    }

    &-link {
        text-align: left;

        &.textCenter {
            text-align: center;
        }

        &.textLeft {
            text-align: left;
        }

        &.textRight {
            text-align: right;
        }

        &-action {
            display: block;
            font-size: 1.6rem;
            line-height: 1.466;

            @media #{$medium-up} {
                font-size: 1.8rem;
            }

            @media #{$large-up} {
                font-size: 1.8rem;
                line-height: 1.636;
            }

            a {
                color: $link-color;
                font-size: inherit;
                font-weight: 600;
                line-height: inherit;
                position: relative;

                @media #{$large-up} {
                    max-width: 500px;
                }
            }

            i {
                color: $link-color;
                font-size: inherit;
                font-weight: 600;
                line-height: inherit;
            }
        }
    }
    &-text {
        text-align: left;

        &.textCenter {
            text-align: center;
        }

        &.textLeft {
            text-align: left;
        }

        &.textRight {
            text-align: right;
        }

        @media #{$medium-up} {
            padding: inherit 4rem;
            text-align: left;
            width: 50%;
        }

        @media #{$large-up} {
            width: 50%;
        }

        p {
            line-height: 1.5;
            margin: 0;
        }

        ul, ol {
            font-weight: normal;
            text-align: left;
            margin: 1.5rem 0 2rem;

            &:first-child {
                margin-top: 0;
            }


            li {
                @include position(relative, null null null null);
                margin: 0 0 1rem 1.75rem;

                @media #{$medium-up} {
                    margin-left: 3rem;
                }
            }
        }

        ul {
            list-style-type: none;
            text-align: left;

            li {
                &:before {
                    @include position(absolute, null null null -1.5rem);
                    content: '\2022';
                }
            }
        }

        p:not(:last-of-type) + {
            ul, ol {
                @include position(relative, null null null null);
                margin-top: -1.5rem;
                text-align: left;

                @media #{$large-up} {
                    margin-top: -2.5rem;
                }
            }
        }

        p, li {
            color: $spray-dk-blue-gray;
            font-size: 1.5rem;
            margin-bottom: 3rem;

            @media #{$medium-up} {
                font-size: 1.8rem;
            }

            @media #{$large-up} {
                font-size: 2rem;
                line-height: 1.45;
                margin-bottom: 4rem;
            }
        }

        p:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        h3.eyebrow {
            border: 0;
            color: $dark;
            font-size: 2.3rem;
            font-weight: 500;
            letter-spacing: 0.24px;
            line-height: 1;
            margin: 0 0 1rem;
            padding: 0;
            text-transform: none;

            @media #{$medium-up} {
                font-size: 2.6rem;
            }

            @media #{$large-up} {
                font-size: 3.2rem;
            }

            @media #{$xlarge-up} {
                font-size: 3.4rem;
            }
        }

        &-copy {
            color: $spray-dk-blue-gray;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.466;
            margin-bottom: 0;
            opacity: 0.9;
            padding: 0 0 1rem;

            @media #{$medium-up} {
                font-size: 1.6rem;
                padding: 0 0 1rem;
            }

            @media #{$large-up} {
                font-size: 1.6rem;
            }

            ul, ol {
                text-align: left;
            }
        }

        &-action {
            display: block;
            font-size: 1.6rem;
            line-height: 1.466;

            @media #{$medium-up} {
                font-size: 1.8rem;
            }

            @media #{$large-up} {
                font-size: 1.8rem;
                line-height: 1.636;
            }

            a {
                color: $link-color;
                font-size: inherit;
                font-weight: 600;
                line-height: inherit;
                position: relative;

                @media #{$large-up} {
                    max-width: 500px;
                }
            }

            i {
                color: $link-color;
                font-size: inherit;
                font-weight: 600;
                line-height: inherit;
            }
        }
    }

    &-top, &-bottom {
        text-align: center;

        &.textCenter {
            text-align: center;
        }

        &.textLeft {
            text-align: left;
        }

        &.textRight {
            text-align: right;
        }

        p {
            line-height: 1.5;
            margin: 0;
        }

        ul, ol {
            font-weight: normal;
            text-align: left;
            margin: 1.5rem 0 2rem;

            &:first-child {
                margin-top: 0;
            }


            li {
                @include position(relative, null null null null);
                margin: 0 0 1rem 1.75rem;

                @media #{$medium-up} {
                    margin-left: 3rem;
                }
            }
        }

        ul {
            list-style-type: none;
            text-align: left;

            li {
                &:before {
                    @include position(absolute, null null null -1.5rem);
                    content: '\2022';
                }
            }
        }

        p:not(:last-of-type) + {
            ul, ol {
                @include position(relative, null null null null);
                margin-top: -1.5rem;
                text-align: left;

                @media #{$large-up} {
                    margin-top: -2.5rem;
                }
            }
        }

        p, li {
            color: $spray-dk-blue-gray;
            font-size: 1.5rem;
            margin-bottom: 3rem;

            @media #{$medium-up} {
                font-size: 1.8rem;
            }

            @media #{$large-up} {
                font-size: 2rem;
                line-height: 1.45;
                margin-bottom: 4rem;
            }
        }

        p:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        h3.eyebrow {
            border: 0;
            color: $dark;
            font-size: 2.3rem;
            font-weight: 500;
            letter-spacing: 0.24px;
            line-height: 1;
            margin: 0 0 1rem;
            padding: 0;
            text-transform: none;

            @media #{$medium-up} {
                font-size: 2.6rem;
            }

            @media #{$large-up} {
                font-size: 3.2rem;
            }

            @media #{$xlarge-up} {
                font-size: 3.4rem;
            }
        }

        &-action {
            display: block;
            font-size: 1.6rem;
            line-height: 1.466;

            @media #{$medium-up} {
                font-size: 1.8rem;
            }

            @media #{$large-up} {
                font-size: 1.8rem;
                line-height: 1.636;
            }

            a {
                color: $link-color;
                font-size: inherit;
                font-weight: 600;
                line-height: inherit;
                position: relative;

                @media #{$large-up} {
                    max-width: 500px;
                }
            }

            i {
                color: $link-color;
                font-size: inherit;
                font-weight: 600;
                line-height: inherit;
            }
        }
    }

    /* Images size variations */
    @media #{$large-up} {
        &.content-image-size-01 {
            .video-feature-image {
                width: 38%;
                max-width: 100%;
            }

            .video-feature-text {
                width: 62%;
            }
        }

        &.content-image-size-02 {
            .video-feature-image {
                width: 71%;
                max-width: 100%;
            }

            .video-feature-text {
                width: 29%;
            }
        }
    }
}
@media #{$medium-up} {
    .video-feature {
        flex-direction: row;

        &.videoLeft {
            .video-feature-image {
                order: 1;
                padding-left: 0;
                padding-right: 0;
            }

            .video-feature-text {
                order: 2;
                padding-right: 0;
            }

            .video-feature-bottom {
                order: 3;
            }

            .video-feature-link {
                order: 4;
            }
        }

        &.videoRight {
            .video-feature-image {
                order: 2;
                padding-left: 0;
                padding-right: 0;
            }

            .video-feature-text {
                order: 1;
                padding-left: 0;
            }

            .video-feature-bottom {
                order: 3;
            }

            .video-feature-link {
                order: 4;
            }
        }

        &-text {
            padding: 0 4rem;
        }
    }
}

.video-playlist-container {
    position: relative;
    background-color: #FFFFFF;
    display: grid;
    padding: 4rem;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-rows: auto auto auto;
    grid-template-areas: "media" "description" "list";

    &::after {
        position: absolute;
        display: block;
        content: "";
        bottom: 2px;
        left: 50%;
        width: 95%;
        transform: translate(-50%, 0);
        border-bottom: 1px dotted $light-gray;
    }

    @media #{$medium-up} {
        height: 56rem;
        padding: 8rem 0 8rem 0;
        grid-template-columns: auto 40vw 35vw auto;
        grid-template-rows: auto auto;
        grid-template-areas: ". media description ." ". media list .";
    }

    .video-feature-image {
        padding: 0;
    }
}

.video-media {
    width: 100%;
    height: 100%;
    grid-area: media;
}

.video-media-description {
    grid-area: description;
    border-bottom: 1px $spray-blue dotted;

    * {
        margin-bottom: 0;
        padding-bottom: 2rem;
    }

    h1 {
        font-size: 3rem;
        font-weight: 500;
    }

    h2 {
        font-size: 2rem;
        font-weight: 300;
    }
}

.video-playlist {
    grid-area: list;
    overflow-y: auto;
    max-height: 40rem;

    @media #{$medium-up} {
        max-height: unset;
    }

    .item {
        display: flex;
        padding: 1rem 0 1rem;
        justify-content: start;
        color: $dark;

        &:first-of-type {
            padding-top: 0;
        }

        &:hover {
            background-color: $lighter-gray;
            text-decoration: underline;
            cursor: pointer;
        }

        .thumbnail {
            position: relative;
            width: 130px;
            pointer-events: none;

            img, video {
                min-width: 130px;
                width: 130px;
                height: 73px;
                object-fit: cover;
            }

            .duration {
                background-color: rgba(0, 0, 0, 0.4);
                color: $lighter-gray;
                font-size: 1.3rem;
                display: none;
                position: absolute;
                top: 53px;
                right: 0px;
                padding: 0.2rem;
            }
        }

        .title {
            padding-left: 2rem;
            font-weight: 500;
            pointer-events: none;
        }

        &.active {
            color: $gray;
        }
    }
}

.video-link-content {
    position: relative;

    @media #{$medium-up} {
        height: 40rem;
    }

    img, video {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        object-position: top;
    }
}
