.flyout-menu-wrapper {
    -webkit-overflow-scrolling: touch;
    background-color: $spray-lt-gray;
    height: 100%;
    overflow-y: auto;
    padding: 3.2rem 1.5rem;
    position: fixed;
    right:-1000px;
    top: 0;
    transition: cubic-bezier(0.79, 0.24, 0.51, 0.95) 0.4s;
    width: 100%;
    z-index: 902;
    @media #{$small-up} { max-width: 400px; }
    @media #{$medium-up} { padding: 3.2rem 2rem; }
    &.active { right: 0; }

    .close-search-button {
        opacity: 1;
        .menu-global {  border-color: $dark; }
        @media screen and (min-width: 1201px ) {
            margin-top: 0;
            top: 13px;
        }
    }
    
    .flyout-menu {
        i {
            color: $spray-lt-blue;
            font-size: 2.5rem;
            line-height: 1;
            transition: all 0.2s ease-in;
            cursor: pointer;
            &.active { transform: rotate(45deg); }
            &.icon-arrow {
                cursor: pointer;
                transform: rotate(90deg);
            }
        }

        &-toggle-back { 
            cursor: pointer;
            margin-bottom: 2rem;
            @media #{$medium-up} { margin-bottom: 3rem; }
            @media #{$large-up} { margin-bottom: 4rem; }
        }

        &-title {  
            line-height: 1.3;
            color: #222;
            font-weight: 500;
            font-size: 2rem;
            @media #{$medium-up} { 
                font-size: 2.4rem; 
                line-height: 1;
            }
            @media #{$large-up} { font-size: 2.8rem;  }
        }
        &-inner-item {
            margin-bottom: 4rem;
            .button {
                cursor: pointer;
                padding: 1.2rem 2.5rem;
            }
            button:disabled { 
                opacity: 0.5;
                pointer-events: none; 
            }
            .button-light-blue { 
                border-width: 0; 
            }
            .button-gray {
                margin-right: 1rem;
            }
        }
        &-list-wrapper {
            padding: 2rem 2rem 0;
            border-top: 2px dotted rgba(63,96,116,0.2);
            margin-bottom: 2rem;
            @media #{$large-up} { 
                &:first-of-type { margin-right: 4rem; }
                margin-bottom: 0; 
            }
        }
        &-see-all {
            .icon-plus, .icon-minus {
                font-size: 1.6rem;
                font-weight: 600;
            }
            a { 
                cursor: pointer; 
                font-weight: 600;
                font-size: 1.4rem;
                @media #{$large-up} { font-size: 1.6rem; }

            }
            .see-less { display: none; }
        }

        &-list {
            list-style-type: none;
            margin-left: 0;
            margin-bottom: 1rem;
            &.collapse { 
                display: none;
            }
            label, div { 
                color: #3F6074;
                font-size: 1.6rem;
                font-weight: 400;
            }
            span {
                font-size: 2rem;
                font-weight: bold;
                cursor:pointer;
            }
        }
        &-list-title {
            font-size: 1.6rem;
            font-weight: 700;
            color: #222;
            margin-bottom: 1rem;
        }
        &-inner-list {
            display: none;
            list-style-type: none;
            margin: 1rem 0 1rem 3rem;
        } 
    }
}

@media #{$large-up} {
    .flyout-menu-wrapper {  max-width: 800px; }
    .flyout-menu-inner-item {
        display: flex; 
        align-items: flex-start;
    }
    .flyout-menu-list-wrapper {
        width: 50%;
    }
}

